@import './variables.scss';

.selectable-card {
    cursor: pointer;
}

.selected-card {
    border: solid 2px $primary-color;
    background-color: $primary-color-1;
}

.checkout-result-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout-result-card {
    padding: 24px 120px;
    text-align: center;
}

.checkout-result-img {
    width: 300px;
    height: auto;
}

.checkout-card-plan {
    .ant-card-body {
        position: relative; 
        height: 200px;
        width: 100%;
        background-image: url('../images/checkout_plan.png');
        background-size: cover;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
    }

}