@mixin mobile {
    @media only screen and (max-width: 990px) {
        @content;
    }
}

@mixin small-mobile {
    @media only screen and (max-width: 767px) {
        @content;
    }
}