@import './../../assets/styles/variables.scss';
@import './../../assets/styles/mixins.scss';

.wrap-orders {
	.action-button {
		.ant-btn {
			color: $primary-color;
			height: 50px;
			padding-left: 10px;
			padding-right: 10px;
			span {
				font-size: 14px;
			}
		}
	}

	.btn1 {
		color: $primary-color;
		border-color: #dddbf4;
		background-color: rgba(108, 93, 211, 0.08);
	}

	.icon-btn {
		width: 18px;
		height: 18px;
		margin-right: 4px;
		vertical-align: middle;
	}

	// .ant-list-split .ant-list-item {
	// 	border-bottom: none;
	// }

	.ant-table-tbody > tr > td {
		border-bottom: 1px solid #afbacc;
	}
	.ant-table-tbody > tr:last-child > td {
		border-bottom: none;
	}

	@include small-mobile {
		h1 {
			font-size: 24px;
		}

		.view-mode-setting {
			> span:first-child {
				display: none;
			}

			display: flex;
			justify-content: space-between;
		}
		button {
			> svg {
				display: none;
			}
		}

		.ant-space {
			width: 100%;

			.ant-space-item:has(.search-input), .ant-space-item:has(button) {
				width: 100%;
	
				.search-input {
					width: 100%;
					padding: 8px;
				}

				button {
					width: 100%;
				}
			}
		}

		.action-button {
			.ant-btn {
				height: fit-content;
			}
		}

		.ant-row {
			margin-bottom: 4px;
		}

		.ant-list-item {
			.ant-col-xs-12:nth-child(2) {
				min-width: 100%;
				flex: auto;
				.ant-row:first-child {
					justify-content: space-between;
					align-items: baseline;

					h3, h4 {
						font-size: 14px;
						max-width: calc(100% / 3);
					}
				}
			}
		}
	}
}

.filter-drawer {
	.ant-collapse-borderless {
		background-color: $white-color;
	}
	.ant-list-split .ant-list-item {
		border-bottom: none;
	}
	.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
		padding: 18px 0px;
	}
	.ant-collapse-borderless > .ant-collapse-item:first-child {
		border-top: 1px solid rgba(0, 0, 0, 0.06);
	}
	.ant-collapse-borderless > .ant-collapse-item {
		border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	}

	.button-date {
		color: $primary-color;
		width: 100%;
		border-color: #dddbf4;
	}

	.button-filter {
		width: 100%;
		margin-top: 18px;
	}
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.order-card-list {
	background-color: #fff;
}

.card-order--detail-btn {
	display: flex;
	height: 100%;
	align-items: center;
	cursor: pointer;
}