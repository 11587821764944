@import '../variables.scss';

.search-input {
    border-radius: 8px !important;
    width: 400px;
    margin-left: 24px;
}

.color-placeholder {
    color: $gray-color;
}