@import './variables.scss';
.text-bold {
    font-weight: bold;
}

.text-medium {
    font-weight: 500;
}

.text-16 {
    font-size: 16px;
}

.pointer {
    cursor: pointer;
}

.btn1 {
    color: $primary-color;
    border-color: #DDDBF4;
    background-color: rgba(108, 93, 211, 0.08);
}

.icon-btn {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    vertical-align: middle;
}

.icon-order-link {
    width: 14px;
    height: 14px;
    margin-left: 8px;
    vertical-align: middle;
    color: $primary-color;
}
