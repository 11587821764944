.integration-card {
    padding: 0 60px;
}

.integration-logo {
    height: 60px;
    width: 160px;
    object-fit: contain;
}

.integration-connect-icon {
    padding: 0 24px;

    svg {
        transform: rotate(90deg);
    }
}