// fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// colors
$primary-color: #6c5dd3;
// $primary-color-1: rgba(108, 93, 211, 0.1);
$primary-color-1: #f0effa;
$primary-color-2: #b2b8fa;
$primary-color-3: rgba(65, 59, 108, 0.72);
$secondary-color: #e1092a;
$secondary-color-1: rgba(225, 9, 42, 0.1);
$white-color: #ffffff;
$success-color: #33824a;
$success-color-1: #ceefd0;
$gray-color: #a4a5a6;
$gray-color-1: #6d6d6d;
$gray-color-2: #e2e2e2;
$warning-color: #ffa940;

:export {
    primaryColor: $primary-color;
    primaryColor1: $primary-color-1;
    primaryColor2: $primary-color-2;
    primaryColor3: $primary-color-3;
    secondaryColor: $secondary-color;
    secondaryColor1: $secondary-color-1;
    whiteColor: $white-color;
    successColor: $success-color;
    successColor1: $success-color-1;
    grayColor: $gray-color;
    grayColor1: $gray-color-1;
    grayColor2: $gray-color-2;
    warningColor: $warning-color;
}