@import './variables.scss';
@import './mixins.scss';

.overview-content {
	.red-color {
		color: $secondary-color !important;
	}

	.purple-color {
		color: $primary-color !important;
	}

	.overview-header {
		position: relative;
		top: -30px;

		h3 {
			font-weight: 500;
		}

		h3.ant-typography,
		h2.ant-typography {
			margin-top: 0px;
			margin-bottom: 0px;
		}

		@include mobile {
			top: 0;
		}
	}

	.overview-main {
		@include mobile {
			padding-top: 30px;
		}

		.ant-card {
			position: relative;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
			border-radius: 20px;
		}

		.overview-card {
			background: $primary-color;
			color: $white-color !important;
			height: 350px;

			@include mobile {
				height: fit-content;
				// display: none;
			}

			@include small-mobile {
				.ant-space {
					flex-direction: column;
					align-items: start;
				}
			}

			.card-image {
				position: absolute;
				right: 0;
				top: 0;
			}

			h3.ant-typography {
				color: $white-color;

				@include small-mobile {
					display: flex;
					flex-direction: column;
					margin-bottom: 0;
					max-width: 50%;
				}
			}

			.card-plan {
				border-radius: 16px;
				min-height: 175px;
			}

			.card-footer {
				position: absolute;
				bottom: 16px;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				text-align: center;

				@include mobile {
					position: static;
					margin-top: 1em;
				}
			}
		}

		.overview-orders {
			min-height: 350px;

			@include mobile {
				.ant-card-body {
					.ant-row {
						flex-direction: column;
						align-items: start;

						span {
							margin: 0;
						}
					}
				}

				.ant-space-vertical {
					flex-direction: row;

					>.ant-space-item {
						margin-right: 0.2em;
					}
				}

				a {
					width: 100%;
				}

				span {
					padding-top: 0.4em;
					padding-bottom: 0.4em;
				}
			}

			.ant-list-split .ant-list-item {
				border-bottom: none;
			}

			.ant-btn {
				padding: 0px;
				width: 125px;
				color: $primary-color;

				@include mobile {
					width: 100%;
				}
			}

			.list-button {
				font-size: 14px;
				font-weight: 500;
			}

			.count-list {
				border-radius: 8px;
				padding: 3px;
			}

			.green-box {
				color: #65B569;
				background: #F0FAF0;
			}

			.red-box {
				color: #E1092A;
				background: #F6E1E4;
			}

			.orange-box {
				color: #F46615;
				background: #FFE4BC;
			}
		}

		.overview-order-sumary {
			min-height: 350px;
		}
	}
}