@import './variables.scss';

.notification-settings {
    .whatsapp {
        .general-settings {
            width: 720px;
            max-width: 90%;

            .save-btn {
                text-align: right;
            }

            .section {
                padding-top: 24px;
                margin-bottom: 24px;
                border-top: 1px solid #ccc;
            }

            .section:first-child {
                padding-top: 0;
                border-top: 0;
            }

            .ant-btn {
                display: inline-flex;
                align-items: center;
            }

            .remove-icon {
                font-size: 24px;
                position: absolute;
                left: calc(100% + 12px);
                top: 12px;
                cursor: pointer;
            }
        }

        .templates {
            .util-bar {
                display: flex;
                justify-content: space-between;

                .filter {
                    flex: 1;
                    background-color: #f0effa;
                    border-radius: 12px;
                    align-items: center;

                    .search {
                        width: 320px;
                        max-width: 20%;
                    }

                    .select {
                        width: 240px;
                        max-width: 15%;
                    }
                }
            }

            .template-message {
                .faint {
                    color: #999;
                }

                .template {
                    max-width: 400px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.notification-automatic {
    .ant-table-wrapper {
        max-width: unset;
        width: fit-content;
        min-width: 100%;
    }
}

.notification-events {
    .ant-table-thead {
        th {
            white-space: nowrap;
        }
    }

    .notification-channels {
        .channel {
            height: 40px;
            line-height: 40px;
            white-space: nowrap;
        }
    }

    .notification-template {
        .template-action {
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 24px;
            color: $primary-color;
        }
    }

    td.ant-table-cell {
        vertical-align: top;
    }

    .additional-settings-container {
        .ant-collapse-header {
            color: $primary-color;
            text-decoration: underline;
        }

        .ant-collapse-header,
        .ant-collapse-content-box {
            padding: 0;
        }

        .additional-settings {
            .ant-form-item-control-input {
                min-height: 0;

                input {
                    width: 600px;
                    max-width: 80%;
                }
            }

            .ant-form > * {
                margin-bottom: 8px;
            }

            .checkbox-select {
                display: flex;

                .checkbox-label {
                    padding: 0 8px;

                    .ant-form-item {
                        display: inline-block;
                        margin: 0;
                        height: 22px;
                    }

                    > * + * {
                        margin-left: 4px;
                    }
                }
            }

            .ant-row {
                margin-bottom: 8px;
            }
        }
    }
}

.edit-whatsapp-content {
    .template-select {
        border-bottom: 2px solid #9faebb;

        .ant-select {
            width: 240px;
        }
    }

    .edit-preview {
        display: flex;

        .edit {
            flex: 1;
            margin-right: 24px;

            .variable {
                color: $primary-color;
            }
        }

        .preview {
            flex: 1;

            .preview-box {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f0f4f7;
                padding: 80px;

                .message {
                    width: 320px;
                    background-color: #fff;
                    border-radius: 12px;
                    overflow: hidden;

                    .header {
                        padding: 12px;
                        font-weight: 600;
                    }

                    .header.DOCUMENT,
                    .header.IMAGE,
                    .header.VIDEO {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: rgb(234, 211, 183);
                        min-height: 160px;
                    }

                    .body {
                        padding: 12px;
                    }

                    .footer {
                        padding: 12px;
                        font-size: 12px;
                    }

                    .buttons {
                        border-top: 1px solid #e6e8eb;
                        padding: 12px;
                        display: flex;
                        flex-wrap: wrap;

                        .url-button {
                            flex: 1;
                            min-width: 49%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.ant-pagination-options {
    .ant-select-selector {
        height: 32px !important;
        .ant-select-selection-item {
            line-height: 32px;
        }
    }
}

.sent-message-logs {
    .ant-table-wrapper {
        max-width: unset;
        width: fit-content;
        min-width: 100%;
    }

    .action {
        .ant-btn {
            height: 30px;
        }
    }

    .log-utils {
        display: flex;

        .search {
            width: 320px;
            margin-left: auto;
        }
    }
}

.iframe-wrapper {
    width: 100%;
    height: calc(100vh - 200px);
}

.notification-campaigns {
    .header {
        display: flex;
        .utils {
            margin-left: auto;
        }
    }

    .action:not(th) {
        text-align: right;

        .ant-btn {
            height: 30px;
        }
    }
}

.create-campaign {
    .select-channel {
        .ant-radio-group {
            width: 100%;
            display: flex;

            .ant-radio-button-wrapper {
                height: 148px;
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .channel {
            display: flex;
            flex-direction: column;
            align-items: center;

            .name {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }

    .choose-audience {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 8px;
        min-height: 328px;

        .ant-tabs-tabpane {
            padding: 0 !important;
        }

        .ant-picker {
            width: 100%;
        }

        .audience {
            display: flex;

            > * {
                flex: 1;
                padding: 16px;
                min-height: 328px;
            }

            > * + * {
                border-left: 1px solid #eee;
            }

            .preview {
                .ant-table {
                    border: 1px solid #eee;
                }
            }

            .rule {
                .remove { 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
    }

    .write-message {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 8px;

        .ant-tabs-tabpane {
            padding: 16px;
        }

        .template-select:not(:has(+ .edit-preview)) {
            border: 0;
        }
    }

    .send-time-options {
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #ccc;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ant-radio-button-wrapper {
            height: initial;
            width: 100%;
            padding: 12px;
        }

        .send-time-option {
            display: flex;
            align-items: center;
            min-width: 540px;

            .label {
                margin-left: 12px;

                h1 {
                    color: inherit;
                }
            }
        }
    }

    .complete {
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #ccc;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 320px;

        .ant-btn {
            display: flex;
            align-items: center;

            svg {
                margin-right: 8px;
            }
        }
    }

    .steps-nav {
        display: flex;
        margin-top: 16px;

        .button-next {
            margin-left: auto;
        }
    }
}
