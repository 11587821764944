@import './variables.scss';
@import './mixins.scss';

.authentication-container {
    padding: 40px 56px;
    min-height: 100vh;

    @include small-mobile {
        max-width: 24em;
        padding: 0;
        margin: 0 auto;
    }
}

.authentication-header--wrapper {
    margin-bottom: 40px;

    @include small-mobile {
        padding: 2em 1em 0 1em;
        margin-bottom: 2em;

        >div {
            > :first-child {
                display: none;
            }
        }

        button {
            padding: 2px 12px;
            font-size: 14px;
        }
    }
}

.authentication-content--wrapper {
    min-height: 80vh;

    @include small-mobile {
        min-height: fit-content;

        > :first-child {
            margin-top: 1em;
        }
    }
}

.authentication-content {
    border-radius: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    label[for=email_otp_code],
    label[for=mobile_otp_code] {
        width: 100% !important;
    }

    @include small-mobile {
        padding-bottom: 1em;

        h3 {
            line-height: 20px;
            font-size: 14px;
            margin-bottom: 0.778em;
            margin-top: 1em;
        }

        form {
            margin-top: 0;

            .ant-form-item {
                margin-bottom: 0.313em;
            }

            div:has(a) {
                margin-top: 0.8em;
            }

            button[type="submit"] {
                margin-top: 2.4em;
                font-size: .95em;

                +.ant-row {
                    margin-top: 0.6em;

                    label {
                        margin-bottom: 1em;
                    }
                }
            }
        }
    }
}

.authentication-block--img {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include small-mobile {

        .ant-typography,
        p,
        img {
            display: none;
        }

        > :last-child {
            margin-top: 2.4em;
            margin-bottom: 2.4em;
        }
    }
}

// Social buttons
.social-authentication-button {
    width: 100%;
    height: 48px;
    position: relative;
    font-weight: 600;

    img {
        width: 28px;
        height: 28px;
        object-fit: contain;
        left: 15px;
        top: 10px;
        position: absolute;
    }

    @include small-mobile {
        margin: 0;
    }
}

.social-authentication-button--facebook {
    background-color: #1877F2;
    color: $white-color;
    border: solid 1px #1877F2 !important;

    @include small-mobile {
        margin-bottom: 5px;
    }
}

.social-authentication-button--facebook:hover {
    background-color: #1877F2;
    color: $white-color;
}

.social-authentication-button--google {
    background-color: #FFFFFF;
    color: rgba(0, 0, 0, 0.54);
    border: solid 1px #dadce0 !important;
}

.social-authentication-button--google:hover,
.social-authentication-button--google:active {
    background-color: #FAFAFA;
    color: rgba(0, 0, 0, 0.54);
}

// progress bar
.authentication-progress-bar {
    position: absolute !important;
    left: 24px !important;
    top: -8px !important;
    width: calc(100% - 48px) !important;
}