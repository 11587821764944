.phone-input-dropdown {
    .ant-select-item-option-content img {
        width: 20px;
        height: 13px;
        object-fit: cover;
        margin-right: 8px;
    }
}

.phone-input-wrapper {
    .ant-input-group-addon {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }

    .ant-select-selector {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .ant-select {
        width: 80px !important;
    }

    .ant-select-selection-item img {
        width: 20px;
        height: 13px;
        object-fit: cover;
        margin-right: 8px;
        border-radius: 0 !important;
    }

    .ant-input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}